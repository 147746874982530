import { Aqua } from "../components/Aqua/aqua";
import { Cards } from "../components/Cards/cards";
import { Footer } from "../components/Footer/footer";
import { Header } from "../components/Header/header";
import { Mechanisms } from "../components/Mechanisms/mechanisms";
import { Results } from "../components/Results/results";
import { Spreadsheet } from "../components/Spreadsheet/spreadsheet";

export function MainPage() {
  return (
    <>
      <Header />
      <main>
        <Cards />
        <Mechanisms />
        <Spreadsheet />
        <Aqua />
        <Results />
        <Footer />
      </main>
    </>
  );
}
