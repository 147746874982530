import styled from "styled-components";

export const SpreadsheetStyled = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 50px;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0 10px;
  }

  .container header {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid;
    width: 100%;
    height: 40px;
    background-color: black;
    h5 {
      color: #ffffff;
      font-size: 16px;
    }
  }

  .box-div-wrapper {
    display: flex;
    position: relative;
  }

  .box-div-wrapper div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .box-div-wrapper div > label {
    border: 1px solid;
    border-bottom: none;
    text-align: center;
    background-color: #d3d3d3;
    font-weight: 700;
    font-size: 8px;
    height: 65%;
  }

  .box-div-wrapper div > input {
    outline: none;
    width: 100%;
    text-align: center;
    border: 1px solid;
    height: 30px;
  }

  select {
    outline: none;
    width: 100%;
    text-align: center;
    border: 1px solid;
    height: 30px;
    text-align: left;
  }

  .box-div-wrapper div > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .box-div-wrapper-result {
    display: flex;
    margin-top: 30px;
    gap: 20px;
  }

  .box-div-wrapper-result div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .box-div-wrapper-result div > label {
    border: 1px solid;
    border-bottom: none;
    text-align: center;
    background-color: #d3d3d3;
    font-weight: 700;
    font-size: 12px;
    height: 30px;
  }

  .warning {
    background-color: #ffffff;
    position: absolute;
    top: -39px;
    width: 100%;
    height: 35px;
    font-size: 10px;
    border-radius: 8px;
    border: 1px solid;
    padding: 5px;
    text-align: center;
  }

  .box-div-wrapper-result div > p {
    text-align: center;
    border: 1px solid;
  }

  @media (min-width: 647px) {
    .box-div-wrapper div > label {
      font-size: 12px;
    }

    .box-div-wrapper-result div > label {
      font-size: 16px;
    }

    .warning {
      top: -40px;
      font-size: 12px;
    }
  }

  @media (min-width: 960px) {
    .warning {
      font-size: 16px;
    }

    .container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 900px;
    }

    .box-div-wrapper div > label {
      padding: 0 20px;
      font-size: 16px;
      height: 40px;
    }

    .box-div-wrapper-result div > label {
      padding: 0 10px;
      font-size: 16px;
    }
  }
`;
