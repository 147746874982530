import { AquaStyled } from "./style";
import aqua from "../../asserts/images/aqua.jpg";

export function Aqua() {
  return (
    <AquaStyled>
      <a
        target="_blank"
        href="https://www.youtube.com/playlist?list=PLehUS8OHrDkx6zIt88a193tsJvhoUGrhv"
      >
        <img src={aqua} alt="" />
      </a>
    </AquaStyled>
  );
}
