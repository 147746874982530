import styled from "styled-components";

export const ResultsStyled = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  .div-results {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1100px;
    gap: 20px;
    max-width: 100%;
  }

  .div-results h2 {
    background: rgb(46, 124, 63);
    background: linear-gradient(
      95deg,
      rgba(46, 124, 63, 1) 14%,
      rgba(17, 108, 17, 1) 25%,
      rgba(5, 121, 7, 1) 53%,
      rgba(10, 73, 5, 1) 78%,
      rgba(8, 64, 7, 1) 88%,
      rgba(4, 46, 10, 1) 100%
    );
    color: #ffffff;
    padding: 10px 90px;
    border-radius: 8px;
  }

  .box-cards {
    display: flex;
    width: 100%;
    padding: 10px;
  }

  .box-cards div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
  }

  .box-cards div > h3 {
    background-color: #dcdcdc;
    padding: 10px 20px;
    width: 100%;
    font-size: 8px;
    text-align: center;
    height: 40px;
  }

  .img-1 {
    width: 80px;
    height: 80px;
  }

  .img-2 {
    width: 110px;
    height: 80px;
  }

  .img-3 {
    width: 110px;
    height: 80px;
  }

  @media (min-width: 647px) {
    .box-cards div > h3 {
      font-size: 14px;
      min-height: 50px;
    }

    .img-1 {
      width: 100px;
      height: 100px;
    }
    .img-2 {
      width: 140px;
      height: 100px;
    }
    .img-3 {
      width: 140px;
      height: 100px;
    }
  }

  @media (min-width: 960px) {
    .box-cards div > h3 {
      font-size: 18px;
      max-height: 40px;
    }

    .img-1 {
      width: 120px;
      height: 120px;
    }
    .img-2 {
      width: 180px;
      height: 120px;
    }
    .img-3 {
      width: 180px;
      height: 120px;
    }
  }
`;
