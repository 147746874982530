import styled from "styled-components";

export const MechanismsStyled = styled.section`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  margin-bottom: 80px;
  max-width: 100%;

  .div-mechanisms {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 1100px;
    gap: 20px;
    max-width: 100%;
  }

  .div-mechanisms h2 {
    margin-bottom: 30px;
    background: rgb(46, 124, 63);
    background: linear-gradient(
      95deg,
      rgba(46, 124, 63, 1) 14%,
      rgba(17, 108, 17, 1) 25%,
      rgba(5, 121, 7, 1) 53%,
      rgba(10, 73, 5, 1) 78%,
      rgba(8, 64, 7, 1) 88%,
      rgba(4, 46, 10, 1) 100%
    );
    color: #ffffff;
    padding: 10px 20px;
    border-radius: 8px;
  }

  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 28px;
    width: 100%;
  }

  .div-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    min-width: 360px;
  }

  .cards {
    background-color: #dcdcdc;
    padding: 10px;
    border-radius: 8px;
    font-size: 10px;
    width: 120px;
    font-weight: 600;
  }

  .box-card {
    position: relative;
  }

  .card-1 {
    background-color: #696969;
    padding: 10px;
    border-radius: 8px;
    font-size: 10px;
    font-weight: 600;
    width: 80px;
    color: #ffffff;
  }

  .card-2 {
    background-color: #dcdcdc;
    padding: 10px;
    border-radius: 8px;
    font-size: 10px;
    width: 120px;
    font-weight: 600;
  }

  .card-3 {
    background-color: #dcdcdc;
    padding: 10px;
    border-radius: 8px;
    font-size: 10px;
    width: 120px;
    font-weight: 600;
  }

  .card-4 {
    background-color: #dcdcdc;
    padding: 10px;
    border-radius: 8px;
    font-size: 10px;
    width: 120px;
    font-weight: 600;
  }

  .card-5 {
    background-color: #dcdcdc;
    padding: 10px;
    border-radius: 8px;
    font-size: 10px;
    font-weight: 600;
    width: 80px;
  }

  .arrow-down-1 {
    position: absolute;
    left: 54px;
    top: 90px;
    font-size: 21px;
  }

  .arrow-down-2 {
    position: absolute;
    left: 50px;
    top: 95px;
    font-size: 21px;
  }

  .arrow-down-3 {
    position: absolute;
    top: 127px;
    left: 52px;
    font-size: 21px;
  }

  .arrow-down-4 {
    position: absolute;
    top: 210px;
    left: 30px;
    font-size: 21px;
  }

  svg {
    color: green;
    font-size: 20px;
  }

  @media (min-width: 647px) {
    .div-box {
      min-width: 610px;
    }

    .cards {
      width: 230px;
      font-size: 14px;
    }

    .box-card {
    }

    .card-1 {
      width: 110px;
      font-size: 14px;
    }

    .card-2 {
      width: 230px;
      font-size: 14px;
    }

    .card-3 {
      width: 230px;
      font-size: 14px;
    }

    .card-4 {
      width: 230px;
      font-size: 14px;
    }

    .card-5 {
      width: 110px;
      font-size: 14px;
    }

    .arrow-down-1 {
      left: 105px;
      top: 78px;
    }

    .arrow-down-2 {
      left: 105px;
      top: 92px;
    }

    .arrow-down-3 {
      left: 105px;
      top: 113px;
    }

    .arrow-down-4 {
      top: 205px;
      left: 45px;
    }
  }

  @media (min-width: 960px) {

    .div-box {
      max-width: 100%;
      width: 1060px;
    }

    .cards {
      width: 400px;
      font-size: 16px;
      padding: 15px;
    }

    .card-1 {
      width: 110px;
      font-size: 16px;
    }

    .card-2 {
      width: 400px;
      font-size: 16px;
    }

    .card-3 {
      width: 400px;
      font-size: 16px;
    }

    .card-4 {
      width: 400px;
      font-size: 16px;
    }

    .card-5 {
      width: 110px;
      font-size: 16px;
    }

    .arrow-down-1 {
      left: 218px;
      top: 63px;
    }

    .arrow-down-2 {
      left: 218px;
      top: 72px;
    }

    .arrow-down-3 {
      left: 218px;
      top: 82px;
    }

    .arrow-down-4 {
      top: 175px;
      left: 45px;
    }
  }

  @media (min-width: 1084px) {

.div-box {
  max-width: 100%;
  width: 1070px;
}

.cards {
  width: 460px;
  font-size: 16px;
  padding: 15px;
}

.card-1 {
  width: 110px;
  font-size: 16px;
}

.card-2 {
  width: 460px;
  font-size: 16px;
}

.card-3 {
  width: 460px;
  font-size: 16px;
}

.card-4 {
  width: 460px;
  font-size: 16px;
}

.arrow-down-1 {
  left: 218px;
  top: 63px;
}

.arrow-down-2 {
  left: 218px;
  top: 72px;
}

.arrow-down-3 {
  left: 218px;
  top: 82px;
}

.arrow-down-4 {
  top: 175px;
  left: 45px;
}
}
`;
