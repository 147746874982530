import { MechanismsStyled } from "./style";
import { GoArrowRight } from "react-icons/go";
import { GoArrowLeft } from "react-icons/go";
import { GoArrowDown } from "react-icons/go";
import { GoArrowUp } from "react-icons/go";

export function Mechanisms() {
  const CustomArrowUp = () => (
    <svg
      xmlns
      viewBox="0 0 16 16"
      fill="none"
      stroke="currentColor"
      strokeWidth="2" // Aqui você define a espessura da linha
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8 12V4M4 8l4-4 4 4" />
    </svg>
  );

  return (
    <MechanismsStyled>
      <div className="div-mechanisms">
        <h2>MECANISMO DE AÇÃO</h2>
        <section>
          <div className="div-box">
            <div className="box-card">
              <div className="card-1">Introdução de ração</div>
              <GoArrowUp className="arrow-down-4" />
            </div>
            <GoArrowRight />
            <div className="cards">TCP regula a relação C/N</div>
            <GoArrowRight />
            <div className="box-card">
              <div className="card-2">
                Aumento de mecanismos benéficos e naturais do ambiente
              </div>
              <GoArrowDown className="arrow-down-1" />
            </div>
          </div>
          <div className="div-box">
            <div className="box-card">
              <div className="card-3">
                Bactéria nitrificantes que ajudam a manter a qualidade da água.
              </div>
              <GoArrowDown className="arrow-down-2" />
            </div>
            <GoArrowLeft />
            <div className="cards">
              Produção de biopolímero natural, armazenado por microrganismos especificos como reserva de carbono e energia.
            </div>
          </div>
          <div className="div-box">
            <div className="cards">Bactérias se fixam no trato digestivo, auxiliando na saúde e na resistência dos camarões a doença</div>
            <GoArrowRight />
            <div className="box-card">
              <div className="card-4">
                Estimula crescimento do fitoplacton, fonte de alimento natural, além de atuar na regulação da oxigenação da água.
              </div>
              <GoArrowDown className="arrow-down-3" />
            </div>
          </div>
          <div className="div-box">
            <div className="card-5">Excreta de camarões</div>
            <GoArrowLeft />
            <div className="cards">Microrganismos viram fonte de proteína para os camarões.</div>
            <GoArrowLeft />
            <div className="cards">
              Formação de bioflocos pelo aumento das bactérias heterotróficas, mantendo o equilibrio da qualidade da água.
            </div>
          </div>
        </section>
      </div>
    </MechanismsStyled>
  );
}
