import { CardsStyled } from "./style";

export function Cards() {
  return (
    <CardsStyled>
      <div className="card-div">
        <h3>Antagonismo ao Víbrio</h3>
        <p>
          Proporcionando um <br /> ambiente mais saudável e<br /> seguro para os camarões
        </p>
      </div>
      <div className="card-div">
        <h3>Redução de matéria orgânica</h3>
        <p>
          Promovendo água de melhor<br /> qualidade e condições ideais <br />de
          crescimento.
        </p>
      </div>
      <div className="card-div">
        <h3>Aumento da imunidade</h3>
        <p>
          Proporcionando melhor absorção<br /> de nutrientes e controle de <br />doenças
          bacterianas e virais.
        </p>
      </div>
    </CardsStyled>
  );
}
