import { useForm } from "react-hook-form";
import { SpreadsheetStyled } from "./style";
import { useState } from "react";

export function Spreadsheet() {
  const { register, watch } = useForm({
    mode: "onChange",
  });

  const [showWarning, setShowWarning] = useState("");

  const value = watch();

  const handleFocus = (value) => {
    setShowWarning(value);
  };

  const handleBlur = (value) => {
    setShowWarning(value);
  };

  const formulaTCP =
    (Number(value.area) +
      (Number(value.area) *
        (Number(value.dias) / 25) *
        Number(value.densidade)) /
        100) *
    0.8 *
    (Number(value.nivel) / 5 + 1) * 1.5;

  const litroTCP = formulaTCP.toFixed(3).replace(".", ",");

  const formulaNecessario =
    (8 *
      (Number(value.raçao) *
        0.3 *
        0.88 *
        0.16 *
        (Number(value.proteina) / 200)) -
      (Number(value.raçao) *
        0.3 *
        0.88 *
        0.16 *
        (Number(value.proteina) / 200)) /
        2) *
    2.27 *
    ((100 - Number(value.renovaçao)) / 100) * 0.5;
  const necessario = formulaNecessario.toFixed(3).replace(".", ",");

  return (
    <SpreadsheetStyled>
      <div className="container">
        <header>
          <h5>Calcular Correção de Água</h5>
        </header>
        <div className="box-div-wrapper">
          <div>
            <label htmlFor="">DIAS DE CULTIVO</label>
            <input
              type="number"
              {...register("dias")}
              onFocus={() => handleFocus("dias")}
              onBlur={() => handleBlur("")}
            />
          </div>
          <div>
            <label htmlFor="">
              ÁREA <br /> (ha)
            </label>
            <input
              type="number"
              {...register("area")}
              onFocus={() => handleFocus("area")}
              onBlur={() => handleBlur("")}
            />
          </div>
          <div>
            <label htmlFor="">DENSIDADE (cam/m²)</label>
            <input
              type="number"
              {...register("densidade")}
              onFocus={() => handleFocus("densidade")}
              onBlur={() => handleBlur("")}
            />
          </div>
          <div>
            <label htmlFor="">QTD DE RAÇÃO </label>
            <input
              type="number"
              {...register("raçao")}
              onFocus={() => handleFocus("raçao")}
              onBlur={() => handleBlur("")}
            />
          </div>
          <div>
            <label htmlFor="">NÍVEL DE PROTEÍNA</label>
            <input
              type="number"
              {...register("proteina")}
              onFocus={() => handleFocus("proteina")}
              onBlur={() => handleBlur("")}
            />
          </div>
          <div>
            <label htmlFor="">RENOVAÇÃO DE ÁGUA (%)</label>
            <input
              type="number"
              {...register("renovaçao")}
              onFocus={() => handleFocus("renovaçao")}
              onBlur={() => handleBlur("")}
            />
          </div>
          <div>
            <label htmlFor="">NIVEL M.O.</label>
            <select
              {...register("nivel")}
              onFocus={() => handleFocus("nivel")}
              onBlur={() => handleBlur("")}
            >
              <option value="1">
                1 - Níveis de M.O. abaixo de 2g/kg observado em laudo
              </option>
              <option value="2">
                2 - Apresenta características de solo sem M.O., sem laudo
              </option>
              <option value="3">
                3 - Nível de M.O. maior que 6g/kg observado em laudo
              </option>
              <option value="4">
                4 - Apresenta características de solo com alta carga de M.O. ou
                nível de M.O. maior que 10g/kg comprovará em laudo.
              </option>
            </select>
          </div>
          {showWarning === "dias" ? (
            <span className="warning">
              Informe quantos dias desde o povoamento do viveiro
            </span>
          ) : showWarning === "raçao" ? (
            <span className="warning">
              Informe a quantidade total de ração ofertada nos últimos 7dd
            </span>
          ) : showWarning === "proteina" ? (
            <span className="warning">
              Informe qual nível de proteína da ração utilizado nesse período
              (últimos 7dd)
            </span>
          ) : showWarning === "renovaçao" ? (
            <span className="warning">
              Se houve renovação de água (TPA), informe qual percentual de
              renovação no período (últimos 7dd)
            </span>
          ) : showWarning === "nivel" ? (
            <span className="warning">
              Níveis de Matéria Orgânica observado antes do povoamento
            </span>
          ) : (
            ""
          )}
        </div>
        <div className="box-div-wrapper-result">
          <div>
            <label htmlFor="">LITROS DE TCP</label>
            <p>
              {value.dias && value.area && value.densidade && value.nivel
                ? litroTCP
                : "0"}
            </p>
          </div>
          <div>
            <label htmlFor="">MELAÇO</label>
            <p>{value.raçao && value.proteina ? necessario : "0"}</p>
          </div>
        </div>
      </div>
    </SpreadsheetStyled>
  );
}
