import styled from "styled-components";

export const AquaStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  img {
    width: 370px;
    height: 70px;
  }
`;
