import styled from "styled-components";

export const FooterStyled = styled.footer`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  justify-content: space-between;
  background-color: #006400;
  padding: 30px 10px;

  .footer-box {
    display: flex;
    width: 1100px;
    max-width: 100%;
    align-items: center;
    justify-content: space-between;
  }

  img {
    width: 180px;
  }

  .img-1 {
    height: 82px;
  }

  .footer-div-1 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .footer-div-1 h4 {
    font-size: 14px;
    color: #ffffff;
  }

  .footer-div-2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 150px;
  }

  .footer-div-2 p {
    color: #ffffff;
    font-size: 10px;
  }

  .insta-icon img {
    width: 25px;
  }

  @media (min-width: 647px) {
    img {
      width: 200px;
    }

    .img-1 {
      height: 88px;
    }

    .img-nutrial {
      width: 400px;
    }
    .footer-div-1 h4 {
      font-size: 20px;
    }

    .footer-div-2 p {
      font-size: 14px;
    }

    .footer-div-2 {
      width: 370px;
    }
  }

  @media (min-width: 960px) {
    padding: 30px 70px;

    img {
      width: 300px;
    }

    .img-1 {
      height: 125px;
    }

    .img-nutrial {
      width: 500px;
    }
    .footer-div-1 h4 {
      font-size: 24px;
    }

    .footer-div-2 p {
      font-size: 16px;
    }
  }
`;
