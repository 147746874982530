import { ResultsStyled } from "./style";
import img1 from "../../asserts/images/img-1.png"
import img2 from "../../asserts/images/img-2.png"
import img3 from "../../asserts/images/img-3.png"

export function Results() {
  return (
    <ResultsStyled>
      <div className="div-results">
        <h2>RESULTADOS</h2>
        <div className="box-cards">
            <div>
                <h3>Maior taxa de sobrevivência</h3>
                <img className="img-1" src={img1} alt="" />
            </div>
            <div>
                <h3> Menor conversão alimentar</h3>
                <img className="img-2" src={img2} alt="" />
            </div>
            <div>
                <h3>Aumento de massa</h3>
                <img className="img-3" src={img3} alt="" />
            </div>
        </div>
      </div>
    </ResultsStyled>
  );
}
