import { HeaderStyle } from "./style";
import tcp from "../../asserts/images/TCP.jpg";

export function Header() {
  return (
    <HeaderStyle>
      <div className="div-header">
        <div className="div-h1">
          <img src={tcp} alt="" />
        </div>
        <div className="div-h2">
          <h2>
            A <span>TCP Camarão</span> é um produto <br /> resultado de um
            processo de cultivo e<br />
            desenvolvimento de ecossistemas,
            <br /> formados por grupos de{" "}
            <span>
              microrganismos
              <br />
              100% naturais e benéficos.
            </span>
            <br />
            Para mais informações clique{" "}
            <a target="_blank" href="https://www.globalbio.com.br/">
              aqui
            </a>
            .
          </h2>
        </div>
      </div>
    </HeaderStyle>
  );
}
