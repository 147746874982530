import styled from "styled-components";

export const CardsStyled = styled.section`
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 300px;
  max-width: 100%;
  padding:0 7px;

  .card-div {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding: 20px 10px;
    background-color: #dcdcdc;
    border-radius: 12px;
    border: none;
  }

  .card-div h3 {
    text-align: center;
    font-size: 12px;
    color: #006400;
  }

  .card-div p {
    font-size: 10px;
    line-height: 12px;
    text-align: center;
  }

  @media (min-width: 647px) {
    gap: 50px;
    flex-direction: row;
    padding: 0;

    .card-div h3 {
      font-size: 18px;
    }

    .card-div p {
      line-height: 25px;
      font-size: 16px;
    }

    .card-div {
      padding: 20px 30px;
      min-height: 150px;
    }
  }

  @media (min-width: 960px) {
    gap: 50px;
    flex-direction: row;
    margin-top: 100px;

    .card-div h3 {
      font-size: 18px;
    }

    .card-div p {
      line-height: 25px;
      font-size: 16px;
    }

    .card-div {
      padding: 20px 30px;
      min-height: 150px;
    }
  }
`;
