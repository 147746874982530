import styled from "styled-components";

export const HeaderStyle = styled.header`
  display: flex;
  justify-content: center;
  background-color: #000636;
  width: 100%;
  height: 100px;

  .div-header {
    display: flex;
    justify-content: center;
    padding: 0 20px;
    position: relative;
    width: 1200px;
  }

  .div-h1 {
    display: flex;
    position: static;
    z-index: 1;
    width: 200px;
    height: 200px;
    border-radius: 50%;
  }

  .div-h1 img {
    width: 300px;
    height: 300px;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
  }

  a {
    font-weight: 700;
  }

  h1 {
    svg {
      font-size: 36px;
    }
    span {
      font-size: 26px;
    }
  }

  .div-h2 {
    display: flex;
    align-items: center;
    width: 100%;
    height: 250px;
    justify-content: center;
    position: absolute;
    top: 100px;
    background-color: #006400;
  }

  h2 {
    display: inline-block;
    text-align: center;
    color: #ffffff;
    font-weight: 400;
    span {
      font-weight: 800;
    }
    font-size: 14px;
    margin-top: 100px;
  }

  @media (min-width: 960px) {
    height: 200px;

    .div-header {
      align-items: center;
      justify-content: space-between;
      position: static;
    }

    .div-h2 {
      z-index: 0;
      width: auto;
      position: static;
      background: none;
    }

    h2 {
      font-size: 20px;
      margin-top: 0;
      text-align: right;
    }

    .div-h1 {
      position: static;
      width: 300px;
      height: 290px;
    }
  }
`;
